import React, { useEffect } from 'react';
import skbtn from '../../images/booking-app.png';
import skbtn2 from '../../images/organize-app.png';
import appStoreImg from "../../images/app-store.svg";
import playStoreImg from "../../images/google-play-store.svg";

const RedirectToApp = ({ appType }) => {
    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
        const isAndroid = /android/i.test(userAgent);

        // Function to redirect to the app store or open the app if it's installed
        const redirectToStore = () => {
            if (appType === 'main') {
                if (isIOS) {
                    window.location.href = "https://apps.apple.com/gb/app/scank/id1574492173";
                } else if (isAndroid) {
                    window.location.href = "https://play.google.com/store/apps/details?id=com.scank.smartapps";
                }
            } else if (appType === 'organizer') {
                if (isIOS) {
                    window.location.href = "https://apps.apple.com/gb/app/scankorganizers/id1608159730";
                } else if (isAndroid) {
                    window.location.href = "https://play.google.com/store/apps/details?id=com.scank.organizer";
                }
            }
        };

        // Redirect after a delay to handle frozen state
        const timer = setTimeout(() => {
            redirectToStore();  // Redirect to store after timeout (5 seconds)
        }, 2000);

        // Cleanup timeout if component unmounts or appType changes
        return () => clearTimeout(timer);

    }, [appType]);

    const getMobileOperatingSystem = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Detect iOS
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return 'iOS';
        }

        // Detect Android
        if (/android/i.test(userAgent)) {
            return 'Android';
        }

        // Other mobile OSes can be added here
        return 'unknown';
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column', // Stack elements vertically
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundColor: '#f0f0f0',
                textAlign: 'center',
                padding: '20px',
            }}
        >
            {/* Redirecting text at the top */}
            <p
                style={{
                    fontSize: '20px',
                    color: '#333',
                    marginBottom: '20px', // Space between text and buttons
                }}
            >
                Redirecting... Please wait.
            </p>

            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: '40px' }}>
                {/* First App Button Section */}
                <div>
                    <img
                        className="qrcodeimg"
                        src={skbtn}
                        alt="Scan App"
                        onClick={(e) => {
                            e.preventDefault(); // Prevents the default action
                            const os = getMobileOperatingSystem();
                            if (os === "iOS") {
                                window.location.href = "https://apps.apple.com/gb/app/scank/id1574492173";
                            } else if (os === "Android") {
                                window.location.href = "https://play.google.com/store/apps/details?id=com.scank.smartapps";
                            } else {
                                console.log("Unsupported OS or unknown");
                            }
                        }}
                    />
                    <br />
                    <label
                        onClick={(e) => {
                            e.preventDefault();
                            const os = getMobileOperatingSystem();
                            if (os === "iOS") {
                                window.location.href = "https://apps.apple.com/gb/app/scank/id1574492173";
                            } else if (os === "Android") {
                                window.location.href = "https://play.google.com/store/apps/details?id=com.scank.smartapps";
                            } else {
                                console.log("Unsupported OS or unknown");
                            }
                        }}
                        className="qrcodelbl"
                        style={{ cursor: 'pointer', fontWeight: 'bold', fontSize: '16px' }}
                    >
                        Grab Tickets!
                    </label>
                    <div>
                        <a href="https://apps.apple.com/gb/app/scank/id1574492173" className="confirmation__social-link">
                            <img src={appStoreImg} width="120" height="31" alt="App Store" className="confirmation__social-icon mt-2 mb-4" />
                        </a>
                    </div>
                </div>

                {/* Second App Button Section */}
                <div>
                    <img
                        className="qrcodeimg"
                        src={skbtn2}
                        alt="Organize App"
                        onClick={(e) => {
                            e.preventDefault(); // Prevents the default action
                            const os = getMobileOperatingSystem();
                            if (os === "iOS") {
                                window.location.href = "https://apps.apple.com/gb/app/scankorganizers/id1608159730";
                            } else if (os === "Android") {
                                window.location.href = "https://play.google.com/store/apps/details?id=com.scank.organizer";
                            } else {
                                console.log("Unsupported OS or unknown");
                            }
                        }}
                    />
                    <br />
                    <label
                        onClick={(e) => {
                            e.preventDefault();
                            const os = getMobileOperatingSystem();
                            if (os === "iOS") {
                                window.location.href = "https://apps.apple.com/gb/app/scankorganizers/id1608159730";
                            } else if (os === "Android") {
                                window.location.href = "https://play.google.com/store/apps/details?id=com.scank.organizer";
                            } else {
                                console.log("Unsupported OS or unknown");
                            }
                        }}
                        className="qrcodelbl"
                        style={{ cursor: 'pointer', fontWeight: 'bold', fontSize: '16px' }}
                    >
                        Organize Events!
                    </label>
                    <div>
                        <a href="https://play.google.com/store/apps/details?id=com.scank.smartapps" className="confirmation__social-link">
                            <img src={playStoreImg} width="110" height="31" alt="Google Play Store" className="confirmation__social-icon mt-2 mb-4" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RedirectToApp;
